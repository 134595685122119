@tailwind base;
@tailwind components;
@tailwind utilities;

/* fonts */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Spline+Sans:wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  scroll-behavior: smooth;
}
body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Inter",
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background: #fff;
  scroll-behavior: smooth;
}
.lb {
  --base-height: 16px;
}
input,
select {
  outline: none;
}
.w-11\/12 {
  width: 1170px;
}
.footer-box {
  max-width: 1170px;
  width: 100%;
  position: relative;
  margin-right: auto;
  margin-left: auto;
}
.border {
  border-width: 1px;
  font-family: "Inter";
}
.top-bar {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
  color: #000;
  display: block;
  align-items: center;
  line-height: 1;
  position: relative;
  box-sizing: border-box;
  background-color: #fff;
}
.top-bar-container {
  width: 90%;
  max-width: 1170px;
  padding-left: 10px !important;
  padding-right: 10px !important;
  flex-wrap: nowrap;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}
.top-bar-animate {
  font-family: "Inter";
  font-weight: 400;
}
.menu-top-bar-menu {
  list-style: none;
  margin: 0;
  padding-left: 0;
  text-align: left;
}
.top-bar-li {
  display: inline-block;
}

.top-bar-li a {
  padding: 0 0.65rem;
  line-height: 1;
  padding-left: 0 !important;
  text-decoration: none;
  transition: all 0.2s cubic-bezier(0.68, 0.01, 0.58, 0.75);
}
.top-bar a:hover {
  color: #bf00ff;
}

.nav-bar {
  transition: all 0.4s ease;
  pointer-events: none;
  position: absolute;
  width: 100%;
}
.input-field:focus {
  box-shadow: 0 0 0 4px rgba(235, 185, 248, 0.15);
}
.MuiTypography-body2 {
  font-size: 0.875rem;
  font-family: "Inter" !important;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
}

.MuiMenuItem-root {
  width: auto;
  overflow: hidden;
  font-size: 0.8rem !important;
  box-sizing: border-box;
  min-height: 48px;
  font-family: "Inter" !important;
  font-weight: 400;
  line-height: 1.5;
  padding-top: 6px;
  white-space: nowrap;
  letter-spacing: 0.00938em;
  padding-bottom: 6px;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
  overflow: hidden;
  font-weight: 400 !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: rgb(139, 139, 139) !important;
}
.MuiCheckbox-root {
  color: rgb(211 211 211) !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #000 !important;
}

.MuiSelect-select.MuiSelect-select {
  padding-right: 24px;
  font-family: "Inter";
}

.shop-button:hover {
  box-shadow: "0 0 0 1px inset #0B051D";
  color: #0b051d;
}

.expandable-section {
  max-height: 0;
  overflow: hidden;
  transition: max-height 2s ease-out, padding 0s ease-out;
}

.expandable-section.expanded {
  max-height: 1000px; /* Adjust as needed based on content size */
  padding-top: 0px; /* Optional: Adjust to add padding when expanded */
  padding-bottom: 0px; /* Optional: Adjust to add padding when expanded */
}

.expand-section {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 0;
}

.expand-section.expanded {
  max-height: 1000px; /* Large enough to fit the expanded content */
  opacity: 1;
}

.adjust-controls {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 0;
}

.adjust-controls.visible {
  max-height: 100px; /* Adjust based on content size */
  opacity: 1;
}

.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

.select-items div,
.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  user-select: none;
}

/* .MuiDataGrid-root .MuiDataGrid-cell {
  border-bottom: none !important;
} */

/* animate side menu */

/* Add this to your CSS file */
@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

.side-menu {
  position: fixed;
  top: 0;
  right: 0;
  height: 97vh;
  width: 90%;
  max-width: 500px;
  background: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
  z-index: 20000;
  animation: slideIn 0.3s forwards;
  overflow-y: auto;
}

.side-menu.closed {
  animation: slideOut 0.3s forwards;
}

.side-menu-wide {
  position: fixed;
  top: 0;
  right: 0;
  height: 97vh;
  width: 90%;
  max-width: 600px;
  background: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
  z-index: 20000;
  animation: slideIn 0.3s forwards;
  overflow-y: auto;
}

.side-menu-wide.closed {
  animation: slideOut 0.3s forwards;
}

.MuiDataGrid-root .MuiDataGrid-iconSeparator {
  color: inherit;
  display: none !important;
}

.MuiDataGrid-root {
  flex: 1;
  color: rgb(0, 0, 0) !important;
  border: 2px solid rgb(247, 247, 247) !important;
  height: 100%;
  display: flex;
  outline: none;
  position: relative;
  font-size: 0.875rem;
  box-sizing: border-box;
  font-family: "Inter", "Helvetica", "Arial", sans-serif !important;
  font-weight: 400;
  line-height: 1.43;
  border-radius: 10px !important;
  flex-direction: column;
  letter-spacing: 0.01071em;
}

.MuiDataGrid-root .MuiDataGrid-columnsContainer {
  top: 0;
  left: 10px !important;
  right: 0;
  display: flex;
  overflow: hidden;
  position: absolute;
  border-bottom: 2px solid rgb(247, 247, 247) !important;
  flex-direction: column;
  width: 98% !important;
}

.MuiDataGrid-root .MuiDataGrid-cell {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  border-bottom: 2px solid rgb(247, 247, 247) !important;
  text-overflow: ellipsis;
}

.MuiDataGrid-root .MuiDataGrid-viewport {
  top: 0;
  left: 10px !important;
  right: 0;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  width: 98% !important;
}

.MuiDataGrid-root .MuiDataGrid-window {
  left: 10px !important;
  right: 0;
  bottom: 0;
  position: absolute;
  overflow-x: auto;
  width: 98% !important;
}

.MuiPaper-rounded {
  border-radius: 8px !important;
}

.MuiMenu-paper {
  max-height: calc(100% - 76px);
  width: 150px !important;
  -webkit-overflow-scrolling: touch;
}

.MuiList-padding {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  margin-right: 4px !important;
  margin-left: 4px !important;
}

.MuiListItem-button:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 6px !important;
  /* padding-left: 1px; */
}

.Toastify__toast-body {
  margin: auto 0;
  flex: 1 1 auto;
  padding: 6px;
  display: flex;
  align-items: center;
  font-family: "Inter" !important;
}
/* Custom scroll bar */

.copied-message {
  position: absolute;
  right: 3rem;
  top: 0.5rem;
  background-color: #ffffffaf;
  color: #bf00ff;
  padding: 0.5rem;
  border-radius: 0.5rem;
  animation: fadeInOutUp 2s ease-in-out;
  pointer-events: none;
  white-space: nowrap;
}

@keyframes fadeInOutUp {
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  10% {
    opacity: 1;
    transform: translateY(-10px);
  }
  90% {
    opacity: 1;
    transform: translateY(-10px);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.zoom {
  transition: transform 0.6s;
}
.zoom:hover {
  transform: scale(1.1);
}
.primary-button {
  transition: all 0.2s cubic-bezier(0.68, 0.01, 0.58, 0.75) !important;
  border-radius: 8px 8px 8px 8px;
  font-family: "Inter", Sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-transform: None;
  line-height: 1em;
  letter-spacing: 0em;
  color: #fff;
  background-color: #bf00ff;
  border-style: none;
  padding: clamp(14px, 0.8em, 18px) clamp(18px, 1.8em, 36px);
  align-self: center;
}
.primary-button:hover {
  background-color: #000;
  color: #fff;
  transition: all 0.2s cubic-bezier(0.68, 0.01, 0.58, 0.75) !important;
}

.alternate-button {
  transition: all 0.3s cubic-bezier(0.68, 0.01, 0.58, 0.75) !important;
  border-radius: 8px 8px 8px 8px;
  font-family: "Inter", Sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-transform: None;
  line-height: 1em;
  letter-spacing: 0em;
  color: #000;
  background-color: #fff;
  border-style: none;
  padding: clamp(14px, 0.8em, 18px) clamp(18px, 1.8em, 36px);
  align-self: center;
}
.alternate-button:hover {
  background-color: #f5d8ff;
  color: #000;
  transition: all 0.3s cubic-bezier(0.68, 0.01, 0.58, 0.75) !important;
}

.MuiAvatar-root {
  width: 40px;
  height: 40px;
  display: flex;
  overflow: hidden;
  position: static !important;
  font-size: 1.25rem;
  align-items: center;
  flex-shrink: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1;
  user-select: none;
  border-radius: 50%;
  justify-content: center;
}
.sign-up-section {
  gap: 25px;
  display: flex !important;
  align-items: center;
}
.link-button {
  transition: all 0.25s cubic-bezier(0.42, 0, 0.29, 1.16);
  color: #677471;
  font-family: "Inter", Sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.75;
  text-transform: None;
}
.link-button:hover {
  color: #020202;
}
.link-button-outer:not(:last-child):after {
  /* display: inline-block;
  height: calc(var(--base-height) * 1.5);
    margin-left: 25px;
    align-self: center;
    content: "|";
    width: 2px;
    opacity: .3; */
  /* display: inline-block;
    content: "";
    width: 0.11825rem;
    height: 1.5rem;
    margin-left: 26px;
    align-self: center;
    background-color: #c6c3c5;
    transform: translateY(-1%); */
}
.link-menu-sign-in {
  list-style: none;
  margin: 0;
  padding: 0;
}
.link-menu-item {
  display: inline-block;
}
.link-hca a {
  transition: color 0.25s cubic-bezier(0.42, 0, 0.29, 1.16);
  will-change: color;
  line-height: inherit;
  color: #677471;
}
.top-bar-li2 a {
  padding: 0 0.4rem;
  line-height: 1;
  text-decoration: none;
  transition: all 0.2s cubic-bezier(0.68, 0.01, 0.58, 0.75);
}
.top-bar-list::before {
  content: "|";
  opacity: 0.7;
}
.menuContainer {
  display: flex;
}
.menuItem {
  font-weight: 400;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out, width 0.3s ease-in-out;
  opacity: 1;
}

.menuItemActive {
  color: #020202;
}
.menuItemInactive {
  color: #677471;
}
.menuItem:hover {
  opacity: 1; /* Ensure hovered item stays at full opacity */
}

.menuContainer:not(:hover) .menuItem:not(:hover) {
  opacity: 0.4; /* Reduce opacity of non-hovered items */
}
.menuItem:hover {
  opacity: 1;
  color: #020202;
}
.menu-item ul {
  animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
  animation-duration: 0.45s;
}
.menu-item:not ul {
  opacity: 0;
  transform: translate3d(0, 40px, 0);
}
.menu-item li a {
  color: #677471;
  line-height: 1.2;
  text-transform: none;
}
.menu-item:hover li a {
  transform: scale3d(1, 1, 1);
  transform-origin: 0% 50%;
  transition-timing-function: ease;
  color: #020202;
}
.footer-title {
  font-family: "Inter";
  font-weight: 600;
  font-size: 18px;
  line-height: 1.5;
}
.footer-item-list {
  color: #b1c2bf;
  transition: color 0.3s;
  font-size: 14px;
  display: flex;
  font-family: "Inter";
  font-weight: 400;
  line-height: 2;
}
.footer-item-list:not(:last-child) {
  padding-bottom: calc(3px / 2);
}
.footer-item-list:hover {
  color: #bf00ff;
}
.footer-links {
  color: #bf00ff;
  transition: color 0.3s;
  font-family: "Inter";
}
.footer-links:hover {
  color: #fff;
}
.social-icon {
  cursor: "pointer";
  padding: "14.4px";
  background-color: "#FFFFFF1A";
  border-radius: "50%";
}
.social-icon:hover {
  opacity: 0.9;
}
.batch_heart {
  background: tomato;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: absolute;
  right: -24%;
  top: -9%;
  cursor: pointer;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.ql-toolbar.ql-snow {
  border: none !important;
  box-sizing: border-box;
  font-family: "Inter", "Helvetica", "Arial", sans-serif !important;
  padding: 8px;
}

.ql-container.ql-snow {
  border: none !important;
}

.ql-container {
  box-sizing: border-box;
  font-family: "Inter", Arial, sans-serif !important;
  font-size: 13px;
  height: 100%;
  margin: 0px;
  position: relative;
}

.custom-select {
  position: relative;
  font-family: "Inter";
}

.custom-select select {
  display: none; /*hide original SELECT element: */
}

.select-selected {
  background-color: DodgerBlue;
}

.MuiExpansionPanel-root:before {
  top: -1px;
  left: 0;
  right: 0;
  height: 0px !important;
  content: "";
  opacity: 1;
  position: absolute;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: rgba(0, 0, 0, 0.12);
}
